/** When your routing table is too long, you can split it into small modules **/

const commonRouter = [
  {path: 'main-about', component: () => import(/* webpackChunkName: "en9door_common" */'@/views/main/main-about'), name: 'MainAbout', meta: {title: 'Main About'}},

  //common
  {path: 'profile', component: () => import(/* webpackChunkName: "en9door_common" */'@/views/login/profile'),                         name: 'Profile',            meta: {title: 'Profile'}},
  {path: 'main/class-info', component: () => import(/* webpackChunkName: "en9door_common" */'@/views/info/info-class'),               name: 'MainClass',         meta: {title: 'Main Class'}},
  {path: 'main/teacher/:id?', component: () => import(/* webpackChunkName: "en9door_common" */'@/views/info/info-teacher'),           name: 'MainTeacher',       meta: {title: 'Main Teacher'}, props: true },
  {path: 'main/content', component: () => import(/* webpackChunkName: "en9door_common" */'@/views/info/info-content'),                name: 'MainContent',       meta: {title: 'Main Content'}},
  {path: 'main/guide/call', component: () => import(/* webpackChunkName: "en9door_common" */'@/views/info/info-call-guide'),          name: 'MainCallGuide',     meta: {title: 'Main Call Guide'}},
  {path: 'main/guide/register', component: () => import(/* webpackChunkName: "en9door_common" */'@/views/info/info-register-guide'),  name: 'MainRegisterGuide', meta: {title: 'Main Register Guide'}},

  //board
  {path: 'board/notice', component: () => import(/* webpackChunkName: "en9door_common" */'@/views/info/info-notice'), name: 'Board Notice', meta: {title: 'Board Notice'}},
  {path: 'board/review', component: () => import(/* webpackChunkName: "en9door_common" */'@/views/info/info-review'), name: 'Board Review', meta: {title: 'Board Review'}},
  {path: 'board/faq', component: () => import(/* webpackChunkName: "en9door_common" */'@/views/info/info-faq'), name: 'Board FAQ', meta: {title: 'Board FAQ'}},

  //error
  {path: '/404', component: () => import(/* webpackChunkName: "common" */'@/views/error-page/404'), hidden: true},
  {path: '/401', component: () => import(/* webpackChunkName: "common" */'@/views/error-page/401'), hidden: true}
]


export default commonRouter
