import apiCommon from "@/api/modules/api-common";
import {methods} from '@/api/axios-en9door'

export default {
    state: {
        book: [],
        content: [{
            contentId:'',
            contentName:'',
            createdAt:'',
            description:'',
            useFlag:''
        }],
        cover: []
    },
    getters: {
        contentState: state => state,
    },
    mutations: {
        SET_COVER: (state, cover) => {
            state.cover = cover
        },
        SET_CONTENT: (state, content) => {
            state.content = content
        },
        SET_BOOK: (state, book) => {
            state.book = book
        },
    },
    actions: {
        fetchContent ({commit, state}) {
            // Only fetch if content array is empty or only has the default empty item
            if (state.content.length <= 1 || (state.content.length === 1 && !state.content[0].contentId)) {
                return apiCommon.getContent()
                    .then(res => {
                        res.content = res.content.filter(content => content.useFlag ==='Y')
                        commit('SET_CONTENT', res.content)
                    })
            }
            // Return a resolved promise if data already exists
            return Promise.resolve(state.content)
        },
        fetchCover ({commit, state}) {
            // Only fetch if cover array is empty
            if (state.cover.length === 0) {
                return methods.get(`/ed/common/content/list-books?prefix=website/book/cover/Sample`)
                    .then(res => {
                        res.contentName = 'Book List'
                        commit('SET_COVER', res)
                    })
            }
            // Return a resolved promise if data already exists
            return Promise.resolve(state.cover)
        },
        fetchBook ({commit, state}) {
            // Only fetch if book array is empty
            if (state.book.length === 0) {
                return apiCommon.getBook()
                    .then(res => commit('SET_BOOK', res.book))
            }
            // Return a resolved promise if data already exists
            return Promise.resolve(state.book)
        },
    }
}
