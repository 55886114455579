import apiAssign from "@/api/modules/api-assign";
import apiRegister from "@/api/modules/api-register";

export default {
  state: {
    assign: {
      assignId: null,
      callType: 'Audio(음성 수업)',
      startDate: new Date(),
      classStartTime: ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2),
      assignDays: [],
      isRequestAttendSheet: false,
      courseId: 0,
      contentId: 0,
      teacherId: 0,
      studentId: 0,
      teacher: null,
      student: null,
      comment: '',
      feedback: '',
      assignStatus: '',
      assignCount: 0,
      review: '',
      reviewRating: 5
    }
  },
  getters: {
    assign: state => state.assign
  },
  mutations: {
    SET_ASSIGN: (state, assign) => {
      state.assign = assign
    },
  },
  actions: {
    fetchAssignActive ({commit}) {
      return apiAssign.getAssignActive()
        .then(res => commit('SET_ASSIGN', res))
        .catch(err => commit('SET_ASSIGN', {}))
    },
    fetchAssign ({commit}, searchQuery) {
      let register = apiRegister.getRegisterByQuery(searchQuery)
      let assign = apiAssign.getAssign(searchQuery.assignId)

      Promise.all([register, assign])
          .then(res => {
            let selectedAssign = {}
            selectedAssign = res[1]
            selectedAssign.attendList = res[1].attendList.sort((a,b) => Date.parse(b.attendDate) - Date.parse(a.attendDate))

            selectedAssign.skypeId = res[0].skypeId
            selectedAssign.callAppType = res[0].callAppType
            selectedAssign.feedback = res[0].feedback
            selectedAssign.registeMention = res[0].registeMention

            commit('SET_ASSIGN', selectedAssign)
          })
    },
    saveAssign({commit}, newAssign) {
      return apiAssign.saveAssign(newAssign)
        .then(res => {
           res.assignId != null
            ? commit('SET_ASSIGN', res)
            : commit('SET_ASSIGN', newAssign)
          return res
        })
    },
    putAssign({commit}, newAssign) {
      if (!newAssign) return null
      newAssign.assignId = this.getters.assign.assignId
      return apiAssign.putAssign(newAssign)
        .then(res => {
          res.assignId != null
              ? commit('SET_ASSIGN', res)
              : commit('SET_ASSIGN', newAssign)
          return res
        })
    },
    deleteAssign() {
      if (this.getters.assign != null){
        return apiAssign.deleteAssign(this.getters.assign.assignId)
      }
    }
  }
}
