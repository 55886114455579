import apiCommon from "@/api/modules/api-common";
import util_common from "@/utils/util_common";

const cdnUrl = process.env.VUE_APP_CDN

export default {
  state: {
    cdnUrl: '',
    langType: 'ko',
    isMobile: false,
    size: window.sessionStorage.getItem('size') || 'medium',
    pageAnchor: 'scroll_section_0',
    registerStatusOptions: ['', 'ACTIVE', 'NO_TEACHER', 'NO_TEST'],
    assignStudentStatusOptions: [ 'ACTIVE', 'END'],
    assignStatusOptions: ['', 'ACTIVE', 'END', 'STOP','PAUSE'],
    attendStatusOptions: ['', 'ATTEND', 'NOCLASS', 'POSTPONE', 'NOSHOW'],
    roleOptions: ['', 'EN9DOOR_MANAGER', 'EN9DOOR_STUDENT', 'EN9DOOR_TEACHER'],
    callAppType: [
      { appCode: 'Skype', appName: 'Skype'  },
      { appCode: 'Zoom', appName: 'Zoom'  }
    ],
    weekdays: [
      { value: 1, dayOfWeek: 'Mon', isChecked: false },
      { value: 2, dayOfWeek: 'Tue', isChecked: false },
      { value: 3, dayOfWeek: 'Wed', isChecked: false },
      { value: 4, dayOfWeek: 'Thu', isChecked: false },
      { value: 5, dayOfWeek: 'Fri', isChecked: false },
      { value: 6, dayOfWeek: 'Sat', isChecked: false },
      { value: 7, dayOfWeek: 'Sun', isChecked: false },
    ],
    classStartHourList: [],
    classStartMinuteList: [],
    classStartTimeList: [],
    sidebar: {
      opened: false,
      withoutAnimation: false
    },
    course: [{
      applyEndDate: '',
      applyStartDate: '',
      courseId: '',
      courseName: '',
      createdAt: '',
      description: '',
      duration: '',
      payappLink: '',
      price: '',
      stat: '',
      wage: ''
    }],
    sysconf: [],
  },
  getters: {
    app: state => state,
    isMobile: state => state.isMobile
  },
  mutations: {
    SET_MOBILE: (state, isMobile) => {
      state.isMobile = isMobile
    },
    SET_PageAnchor: (state, anchor) => {
      state.pageAnchor = anchor
    },
    TOGGLE_SIDEBAR: state => {
      state.sidebar.opened = !state.sidebar.opened
    },
    OPEN_SIDEBAR: state => {
      state.sidebar.opened = true
    },
    CLOSE_SIDEBAR: state => {
      state.sidebar.opened = false
    },
    SET_COURSE: (state, course) => {
      state.course = course
    },
    SET_SYSCONF: (state, sysconf) => {
      state.sysconf = sysconf
    },
    SET_EN9DOOR_: (state, sysconf) => {
      state.sysconf = sysconf
    },
    SET_ASSIGN_STATUS: (state, assignStatus) => {
      state.assignStatusOptions = assignStatus
    },
    SET_CLASS_HOUR_LIST : (state, classStartHourList) => {
      state.classStartHourList = classStartHourList
    },
    SET_CLASS_MINUTE_LIST : (state, classStartMinuteList) => {
      state.classStartMinuteList = classStartMinuteList
    },
    SET_CLASS_TIME_LIST : (state, classStartTimeList) => {
      state.classStartTimeList = classStartTimeList
    },
    SET_CDN_URL : (state, cdnUrl) => {
      state.cdnUrl = cdnUrl
    }
  },
  actions: {
    resizeWidth({commit}){
      let flag = util_common.isMobile()
      commit('SET_MOBILE', flag)
    },
    nextAnchor({state,  commit}){
      let currentAnchor = state.pageAnchor
      let anchorString = currentAnchor.substring(0, currentAnchor.length - 1)
      currentAnchor = anchorString + (currentAnchor.substring(currentAnchor.length - 1, currentAnchor.length)* 1 + 1)
      commit('SET_PageAnchor', currentAnchor)
    },
    prevAnchor({state,  commit}){
      let currentAnchor = state.pageAnchor
      let anchorString = currentAnchor.substring(0, currentAnchor.length - 1)
      currentAnchor = anchorString + (currentAnchor.substring(currentAnchor.length - 1, currentAnchor.length)* 1 - 1)
      commit('SET_PageAnchor', currentAnchor)
    },
    toggleSideBar({commit}) {
      commit('TOGGLE_SIDEBAR')
    },
    openSideBar({commit}) {
      commit('OPEN_SIDEBAR')
    },
    closeSideBar({commit}) {
      commit('CLOSE_SIDEBAR')
    },
    setCdnUrl({commit}) {
      commit('SET_CDN_URL', cdnUrl)
    },
    fetchClassStartTime({commit, state}) {
      // Only calculate if the lists are empty
      if (state.classStartTimeList.length === 0) {
        const classStartTimeList = [];
        const classStartHourList = [];
        const classStartMinuteList = [];
        for (let i = 8; i <= 23; i++) {
          classStartHourList.push(String(i).padStart(2, '0'))
          for (let j = 0; j < 60; j = j + 10) {
            classStartMinuteList.push(String(j).padStart(2, '0'))
            classStartTimeList.push(
              String(i).padStart(2, '0') + ":" + String(j).padStart(2, '0') + ":00"
            )
          }
        }
        commit('SET_CLASS_HOUR_LIST', classStartHourList)
        commit('SET_CLASS_MINUTE_LIST', classStartMinuteList)
        commit('SET_CLASS_TIME_LIST', classStartTimeList)
      }
      // Return a resolved promise with the time lists
      return Promise.resolve({
        hours: state.classStartHourList,
        minutes: state.classStartMinuteList,
        times: state.classStartTimeList
      })
    },
    fetchCourse ({commit, state}) {
      if (state.course.length <= 1 || (state.course.length === 1 && !state.course[0].courseId)) {
        return apiCommon.getCourse()
          .then(res => commit('SET_COURSE', res.content))
      }
      return Promise.resolve(state.course)
    },
    fetchSysConfEn9door ({commit, state}) {
      // Only fetch if sysconf array is empty
      if (state.sysconf.length === 0) {
        const en9doorList = apiCommon.getSysConfLike('en9door_')
        const clientIdList = apiCommon.getSysConfLike('_client_id')

        return Promise.all([en9doorList, clientIdList])
          .then((values) => {
            return [].concat.apply([], values);
          }).then(res=> {
            commit('SET_SYSCONF', res)
            return res
          });
      }
      // Return a resolved promise if data already exists
      return Promise.resolve(state.sysconf)
    },
  }
}
